import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import SymptomBlock from 'components/SymptomBlock';
import Button from 'components/common/Button';
import ProductVariats from 'components/ProductVariats';
import ProductFormats from 'components/ProductFormats';
import GatsbyImage from 'components/common/GatsbyImage';
import ProductBuyNowDropdown from 'components/ProductBuyNowDropdown';
import IconCustom from 'components/common/IconCustom';
import WhereToBuyCard from 'components/WhereToBuy/WhereToBuyCard';
import ProductAdditionalInfo from 'components/ProductAdditionalInfo';
import RetailersLink from 'components/RetailersLink';
import { parseBoolean } from 'utils/parseHelpers';
import useScreenRecognition from 'hooks/useScreenRecognition';

import './ProductDetails.scss';

const ProductDetails: FC<ProductPageTypes.IProductDetails> = ({
  productDetailsData,
  tags,
  retailersBox,
  retailerClickHandler,
}) => {
  const {
    descriptionShort,
    productImage,
    productImageAlt,
    symptoms,
    commonProductSettings: {
      availableFlavorsLabel,
      availableSizesLabel,
      buyNowLabel,
      chooseFormatLabel,
    },
    buyNowDropdown,
    buyNow,
    buyNowCards,
    additionalText,
    additionalInfo,
    btnLogo,
    btnLogoAlt,
    buyNowButtonNotice,
    howItWorksButton,
    ariaHowItWorksLabel,
    flavours,
    sizes,
    otherFormats,
    currentFormat,
    ariaBuyNow,
    badgeImage,
    badgeAlt,
  } = productDetailsData;
  const colorTag = tags.find((tag) => parseBoolean(tag.isProductFamily));

  const { isNotMobile, isMobile, isDesktop } = useScreenRecognition();
  const buyNowPath = (buyNow?.[0]?.url || '').concat(buyNow?.[0]?.queryString || '');
  const howItWorksPath = (howItWorksButton?.[0]?.url || '').concat(
    howItWorksButton?.[0]?.queryString || ''
  );

  return (
    <div
      className={classNames('product-details', `product-details--${colorTag?.color?.label}`)}
      data-testid="product-details"
    >
      <Container fluid>
        <div className="product-details__grid">
          {productImage ? (
            <div className="product-details__image">
              {isNotMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                />
              ) : null}
              {isMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                  fadeIn={false}
                />
              ) : null}
            </div>
          ) : null}
          <div className="product-details__text">
            {descriptionShort ? (
              <DangerouslySetInnerHtml
                className="product-details__short-description"
                html={descriptionShort}
              />
            ) : null}
            {symptoms?.length
              ? symptoms.map(({ properties: { text, color } }) => (
                  <SymptomBlock key={text} text={text} color={color} />
                ))
              : null}
          </div>
        </div>
        {badgeImage?.fallbackUrl ? (
          <GatsbyImage
            objectFit="contain"
            className="product-details__badge"
            fluid={badgeImage}
            alt={badgeAlt}
          />
        ) : null}
        {currentFormat?.length ? (
          <ProductFormats {...{ otherFormats, currentFormat }} title={chooseFormatLabel} />
        ) : null}
        <div className="product-details__info">
          {flavours?.length ? (
            <ProductVariats title={availableFlavorsLabel} variants={flavours} />
          ) : null}
          {retailersBox?.retailersLinkItem.length && isDesktop ? (
            <RetailersLink
              retailersLink={retailersBox.retailersLink}
              retailersLinkItem={retailersBox.retailersLinkItem}
            />
          ) : null}
        </div>
        {sizes?.length ? <ProductVariats title={availableSizesLabel} variants={sizes} /> : null}

        {buyNowDropdown?.length > 0 ? (
          <ProductBuyNowDropdown
            buyNowData={buyNowDropdown[0].properties}
            retailerClickHandler={retailerClickHandler}
          />
        ) : null}

        {buyNow?.length && !buyNowDropdown?.length ? (
          <div className="product-details__buy">
            <Button to={buyNowPath} target={buyNow[0].target} ariaLabel={ariaBuyNow} variant="buy">
              {buyNow[0].name}
              {btnLogo?.fallbackUrl ? (
                <GatsbyImage
                  isLazyLoading={false}
                  objectFit="contain"
                  className="product-details__btn-logo"
                  fluid={btnLogo}
                  alt={btnLogoAlt}
                />
              ) : (
                <IconCustom icon="chevron-right" />
              )}
            </Button>
            {buyNowButtonNotice ? (
              <DangerouslySetInnerHtml
                className="product-details__buy-notice"
                html={buyNowButtonNotice}
              />
            ) : null}
          </div>
        ) : null}

        {retailersBox?.retailersLinkItem.length && !isDesktop ? (
          <RetailersLink
            retailersLink={retailersBox.retailersLink}
            retailersLinkItem={retailersBox.retailersLinkItem}
          />
        ) : null}

        {howItWorksButton?.length ? (
          <div className="product-details__how-it-works">
            <Button
              to={howItWorksPath}
              target={howItWorksButton[0].target}
              ariaLabel={ariaHowItWorksLabel}
              variant="info"
            >
              {howItWorksButton[0].name}
            </Button>
          </div>
        ) : null}

        {buyNowCards?.length ? (
          <div className="product-details__buy-cards">
            <h2 className="product-details__buy-cards-title">{buyNowLabel}</h2>
            <div className="product-details__buy-list">
              {buyNowCards.map(({ properties: { title, image, imageAlt, link } }) => (
                <WhereToBuyCard
                  classes={`buy-now__button--${title}`}
                  key={title}
                  {...{ image, imageAlt, link, title, retailerClickHandler }}
                />
              ))}
            </div>
          </div>
        ) : null}

        {additionalText ? (
          <DangerouslySetInnerHtml
            className="product-details__additional-text"
            html={additionalText}
          />
        ) : null}

        {additionalInfo?.length ? (
          <ProductAdditionalInfo
            mainImage={additionalInfo[0].properties.mainImage}
            mainImageAlt={additionalInfo[0].properties.mainImageAlt}
            cards={additionalInfo[0].properties.cards}
            infoBlock={additionalInfo[0].properties.infoBlock}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default ProductDetails;
