import React, { FC, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import ProductBanner from 'components/ProductBanner';
import HomeBanner from 'components/HomeBanner';
import Layout from 'components/Layout';
import ProductInfo from 'components/ProductInfo';
import ProductDetails from 'components/ProductDetails';
import ProductDetailsGavinatura from 'components/ProductDetailsGavinatura';
import ProductIngredients from 'components/ProductIngredients';
import BodyRenderer from 'components/common/BodyRenderer';
import InfoBanner from 'components/InfoBanner';
import InfoSection from 'components/InfoSection';
import FaqSection from 'components/FaqSection';
import FeaturedArticle from 'components/FeaturedArticle';
import RelatedProducts from 'components/RelatedProducts';
import ShopMessage from 'components/ShopMessage';
import WhereToBuy from 'components/WhereToBuy';
import TestimonialSection from 'components/TestimonialSection';
import StoreFinderRedirect from 'components/StoreFinderRedirect';
import TextBlock from 'gatsby-theme-gaviscon/src/components/common/TextBlock';
import DiscoveryTextSection from 'components/DiscoveryTextSection';

import { parseBoolean } from 'utils/parseHelpers';
import { getGaviLayoutFlags } from 'utils/helpers';
import { GAVI_LAYOUT_FLAGS } from 'utils/constants';
import { gtmService, IProductItem } from 'services/gtmService';

import { TProductPageComponentProps } from './models';
import getSchemaScript from './utils';

import BreadCrumbs from '../../components/common/BreadCrumbs';

import './ProductPage.scss';

const elements = {
  'Info banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Info Section': ({ properties }, keyId) => <InfoSection key={keyId} {...properties} />,
  'FAQ block': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Featured Article': ({ properties }, keyId) => <FeaturedArticle key={keyId} {...properties} />,
  'Related Products': ({ properties }, keyId) => <RelatedProducts key={keyId} {...properties} />,
  'Where to buy': ({ properties }, keyId) => <WhereToBuy key={keyId} {...properties} />,
  'Text Block': ({ properties }, keyId) => (
    <div className="container-fluid product-page__text-block">
      <TextBlock key={keyId} text={properties.content} />
    </div>
  ),
  'Testimonial Section': ({ properties }, keyId) => (
    <TestimonialSection key={keyId} {...properties} />
  ),
  'Store Finder Redirect': ({ properties }, keyId) => (
    <StoreFinderRedirect key={keyId} {...properties} />
  ),
  'Ingredient Section': ({ properties }, keyId) => (
    <ProductIngredients key={keyId} {...properties} />
  ),
  'Info Block Section': ({ properties }, keyId) => (
    <ProductInfo key={keyId} {...{ ...properties }} />
  ),
  'Discovery Section': ({ properties }, keyId) => (
    <DiscoveryTextSection key={keyId} {...properties} />
  ),
};

const ProductPage: FC<TProductPageComponentProps> = ({
  data: {
    page: {
      nodes: [pageData],
    },
    brandSettings: { logo, logoAlt },
    commonSettings,
    siteSettings,
    featuredArticles,
    relatedProducts,
    header,
    footer,
  },
  pageContext: { relatedProductsLinks, breadCrumbs },
}) => {
  const {
    productBanner,
    productHomeBanner,
    symptoms,
    title,
    tags,
    btnLogo,
    btnLogoAlt,
    infoSection,
    instructions,
    ingredients,
    descriptionShort,
    productImage,
    productImageAlt,
    badgeAlt,
    badgeImage,
    buyNowDropdown,
    buyNow,
    ariaBuyNow,
    buyNowButtonNotice,
    howItWorksButton,
    ariaHowItWorksLabel,
    buyNowCards,
    additionalText,
    additionalInfo,
    flavours,
    sizes,
    currentFormat,
    otherFormats,
    shopMessage,
    body,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    showBreadcrumbs,
    hideProductDetails,
    hideDisclaimer,
    enableDataSchema,
    schemaName,
    schemaDescription,
    schemaImage,
    schemaBrand,
    pageTheme,
  } = pageData;

  const commonProductSettings = commonSettings?.blocks.find(
    (block) => block.structure === 'Product'
  )?.properties;
  const retailersBox = commonSettings?.blocks.find((block) => block.structure === 'Retailers Link')
    ?.properties;
  const formatLabel = currentFormat?.length ? currentFormat[0].properties.label : null;
  const { GAVIDESIGN, GAVIBANNER, GAVIHEADER, GAVILOGO } = getGaviLayoutFlags(
    tags,
    GAVI_LAYOUT_FLAGS
  );

  const isDisclaimerHidden = parseBoolean(hideDisclaimer);
  const isInternalProductDetailsHidden = parseBoolean(hideProductDetails);

  const AdditionalProductDetails = GAVIDESIGN ? ProductDetailsGavinatura : ProductDetails;

  elements['Product Detail Section'] = ({ properties }, keyId) => (
    <AdditionalProductDetails key={keyId} {...{ ...properties }} />
  );

  const jsonLD = {
    jsonName: schemaName,
    jsonDescription: schemaDescription,
    jsonImage: schemaImage,
    jsonBrand: schemaBrand,
  };

  const productDetailsData = {
    descriptionShort,
    productImage,
    productImageAlt,
    currentFormat,
    otherFormats,
    symptoms,
    buyNowDropdown,
    buyNow,
    buyNowCards,
    additionalText,
    additionalInfo,
    btnLogo,
    btnLogoAlt,
    ariaBuyNow,
    buyNowButtonNotice,
    howItWorksButton,
    ariaHowItWorksLabel,
    sizes,
    commonProductSettings,
    flavours,
    badgeAlt,
    badgeImage,
  };

  useEffect(() => {
    const timeoutId = gtmService.emitProductView(pageData);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  const handleRetailerClick = useCallback(
    (product: IProductItem) => (shopName: string) => {
      gtmService.emitRetailerClick(product, shopName);
    },
    []
  );

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      isDisclaimerHidden={isDisclaimerHidden}
      isGaviDesign={GAVIDESIGN}
      isGaviHeader={GAVIHEADER}
      isGaviLogo={GAVILOGO}
    >
      <div
        className={classnames('product-page', {
          [`product-page--${pageTheme}`]: pageTheme,
        })}
      >
        <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
        {GAVIBANNER ? (
          <HomeBanner topBanner={productHomeBanner} />
        ) : (
          <ProductBanner
            {...{
              ...productBanner[0].properties,
              title,
              logo,
              logoAlt,
              tags,
              formatLabel,
            }}
          />
        )}
        {!isInternalProductDetailsHidden ? (
          <ProductDetails
            productDetailsData={productDetailsData}
            retailersBox={retailersBox}
            tags={tags}
            retailerClickHandler={handleRetailerClick(pageData)}
          />
        ) : null}
        {shopMessage?.[0]?.properties ? (
          <ShopMessage
            title={shopMessage[0].properties.title}
            linkUrl={shopMessage[0].properties.link?.[0]?.url || ''}
            linkText={shopMessage[0].properties.link?.[0]?.name || ''}
            target={shopMessage[0].properties.link?.[0]?.target || ''}
            blocks={shopMessage[0].properties.icons}
          />
        ) : null}
        {infoSection?.length ? (
          <ProductInfo blocks={infoSection} {...{ instructions, ingredients }} />
        ) : null}
      </div>
      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{
          ...{
            relatedProducts,
            featuredArticles,
            commonSettings,
            relatedProductsLinks,
            instructions,
            ingredients,
            productDetailsData,
            retailerClickHandler: handleRetailerClick(pageData),
          },
        }}
      />
      {enableDataSchema ? <Helmet script={getSchemaScript(jsonLD)} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery(
    $relatedProductsLinks: [String]
    $featuredArticlesLinks: [String]
    $link: String = ""
    $lang: String
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          ...FragmentRetailersLink
          availableFlavorsLabel
          availableSizesLabel
          chooseFormatLabel
          buyNowLabel
          title
          preTitle
          secText
          closeModalText
          minText
        }
      }
    }
    brandSettings(lang: { eq: $lang }) {
      logo {
        ...FragmentGatsbyImage
      }
      logoAlt
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allProduct(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        id
        sku
        showBreadcrumbs
        hideProductDetails
        hideDisclaimer
        symptoms {
          properties {
            color
            text
          }
        }
        title
        productImageAlt
        link
        category
        descriptionShort
        instructions
        relatedProductsSku
        additionalInfo {
          properties {
            mainImageAlt
            mainImage {
              ...FragmentGatsbyImage
            }
            cards {
              properties {
                text
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            infoBlock {
              properties {
                text
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
          structure
        }
        buyNowDropdown {
          properties {
            toggleButtonTitle
            toggleButtonAriaLabel
            description
            cancelButtonTitle
            cancelButtonAriaLabel
            dropdownItems {
              properties {
                name
                linkToShop
                ariaLabel
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
        buyNow {
          name
          url
          target
          queryString
        }
        btnLogo {
          ...FragmentGatsbyImage
        }
        btnLogoAlt
        ariaBuyNow
        buyNowButtonNotice
        howItWorksButton {
          name
          url
          target
          queryString
        }
        ariaHowItWorksLabel
        buyNowCards {
          properties {
            link {
              url
              target
            }
            image {
              ...FragmentGatsbyIcon
            }
            imageAlt
            title
          }
        }
        additionalText
        currentFormat {
          properties {
            type
            label
          }
        }
        otherFormats {
          properties {
            type
            label
            link {
              url
            }
          }
        }
        flavours {
          properties {
            icon
            label
            image {
              ...FragmentGatsbyImageSm
            }
          }
        }
        sizes {
          properties {
            label
            icon
          }
        }
        ingredients
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 200, quality: 75) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        badgeImage {
          ...FragmentGatsbyImage
        }
        badgeAlt
        infoSection {
          properties {
            description
            title
            type
            links {
              properties {
                link {
                  url
                }
                linkTo {
                  url
                }
                title
                ariaLabel
              }
            }
          }
        }
        productBanner {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
            }
            imageAlt
            logo {
              ...FragmentGatsbyImage
            }
            hideLogo
            hideVariant
            logoAlt
            subTitle
            mobileImage {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
              mobileImage {
                childImageSharp {
                  fluid(maxWidth: 768, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            mobileImageAlt
            additionalBannerImage {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
            }
            additionalBannerImageAlt
            bannerBackground {
              label
            }
            bannerTheme
          }
        }
        productHomeBanner {
          ...FragmentHomePageTopBanner
        }
        tags {
          name
          id
          color {
            label
          }
          isProductFamily
        }
        shopMessage {
          properties {
            title
            link {
              url
              name
              target
            }
            icons {
              properties {
                icon
                label
              }
            }
          }
        }
        enableDataSchema
        schemaName
        schemaDescription
        schemaImage {
          ...FragmentGatsbyImage
        }
        schemaBrand
        body {
          ... on TDiscoverySectionStructure {
            ...FragmentDiscoverySectionStructure
          }
          ... on TTextBlockStructure {
            structure
            properties {
              content
              maxWidth
              color
            }
          }
          ... on TStoreFinderRedirectStructure {
            structure
            properties {
              ariaButton
              button
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              label
              queryString
              title
            }
          }
          ... on TTestimonialSectionStructure {
            structure
            properties {
              imageAvatar {
                ...FragmentGatsbyImage
              }
              imageAvatarAlt
              bgColor
              description
              imageAlignment
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
            }
          }
          ... on TInfoSectionStructure {
            structure
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlign
              imageAlt
              video {
                structure
                properties {
                  title
                  url
                  link
                  duration
                  ariaLabel
                  videoPreviewPosterAlt
                  videoPreviewPoster {
                    ...FragmentGatsbyImage
                  }
                  playButtonAria
                }
              }
              link {
                url
                name
              }
              ariaCTA
              ctaLogo {
                ...FragmentGatsbyImage
              }
              ctaLogoAlt
              ctaButtonType
              returnsNotice
              ctaButtonNotice
              title
              sectionBackground {
                label
              }
              isShowVideoOnTop
            }
          }
          ... on TWheretobuyStructure {
            structure
            properties {
              color
              title
              listMode
              limit
              limitMobile
              cards {
                properties {
                  imageAlt
                  type
                  title
                  link {
                    url
                    target
                    queryString
                  }
                  image {
                    ...FragmentGatsbyImage
                  }
                }
              }
            }
          }
          ... on TFeaturedArticleStructure {
            structure
            properties {
              link {
                url
                name
              }
              color
              ariaButton
              imageAlign
              isManual
              manualTitle
              manualDescription
              manualImage {
                ...FragmentGatsbyProps
                gatsbyImage {
                  ...FragmentMiddleImage
                }
                mobileImage {
                  childImageSharp {
                    fluid(maxHeight: 204) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              manualImageAlt
            }
          }
          ... on TInfobannerStructure {
            structure
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
            }
          }
          ... on TRelatedProductsStructure {
            structure
            properties {
              ariaAllProducts
              limit
              title
              hideVariant
              isFamily
              linkAllProducts {
                name
                url
              }
              cardTheme
            }
          }
          ... on TFAQblockStructure {
            structure
            properties {
              title
              color
              selectedIndex
              isExpanded
              items {
                properties {
                  answer
                  question
                }
              }
              disableSeoSchema
            }
          }
          ... on TIngredientSectionStructure {
            ...FragmentProductIngredients
          }
          ... on TProductDetailSectionStructure {
            structure
          }
          ... on TInfoBlockSectionStructure {
            structure
            properties {
              blocks: infoBlock {
                properties {
                  title
                  description
                  type
                  links {
                    properties {
                      link {
                        url
                      }
                      linkTo {
                        url
                      }
                      title
                      ariaLabel
                    }
                  }
                }
              }
              sectionBackground {
                label
              }
            }
          }
        }
        pageTheme
      }
    }
    featuredArticles: allUmbracoArticles(filter: { link: { in: $featuredArticlesLinks } }) {
      nodes {
        title
        link
        text
        tags {
          articleCategory
          name
        }
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentMiddleImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 204) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
      }
    }
    relatedProducts: allProduct(filter: { link: { nin: [$link], in: $relatedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyImage
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
  }
`;

export default ProductPage;
